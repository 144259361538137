<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-8">&nbsp;</v-col>
      <v-col class="col-2">
        <v-select
          label="Filter chase count"
          :items="[0,1,2,3]"
          v-model="chaseCountFilter"
        ></v-select>
      </v-col>
      <v-col class="col-1 pt-8">
        <v-btn small @click="filter">Filter</v-btn>
      </v-col>
      <v-col class="col-1 pt-8">
        <v-btn small @click="resetFilter">Reset</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <h1>
          My Open Quotations
          <span class="float-right">
            Total value:
            {{ total | priceInPounds | currency }}
          </span>
        </h1>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Expires</th>
                <th class="text-left">Value</th>
                <th class="text-left">By</th>
                <th class="text-left">Contact</th>
                <th class="text-left">Email</th>
                <th class="text-left">Telephone</th>
                <th class="text-left">Chased</th>
                <th class="text-left">Chase</th>
                <th class="text-left">Lost</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="11">&nbsp;</td>
                <td>
                  <v-checkbox @change="chaseAll"></v-checkbox>
                </td>
                <td>
                  <v-checkbox @change="loseAll"></v-checkbox>
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr
                v-for="quotation in filteredQuotations"
                :key="quotation.id"
              >
                <td>{{ quotation.jobNumber }} {{ quotation.expired }}</td>
                <td>
                  <router-link :to="`/customers/view/${quotation.customerId}`">
                    {{ quotation.customer }}
                  </router-link>
                </td>
                <td>{{ quotation.date | tinyDate }}</td>
                <td>{{ quotation.site }}</td>
                <td>{{ quotation.expires | tinyDate }}</td>
                <td>
                  <span v-if="quotation.value > quotation.minChargeAmount">
                    {{ quotation.value | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ quotation.minChargeAmount | priceInPounds | currency }}
                  </span>
                </td>
                <td>{{ quotation.quotedByInitials }}</td>
                <td>{{ quotation.contact }}</td>
                <td>{{ quotation.contactEmail }}</td>
                <td>{{ quotation.contactTel }}</td>
                <td>{{ quotation.chaseCount }}</td>
                <td>
                  <v-checkbox v-if="quotation.chaseCount < 3" v-model="quotation.chase"></v-checkbox>
                </td>
                <td>
                  <v-checkbox v-model="quotation.lost"></v-checkbox>
                </td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/quotations/chase-history/${quotation.id}`">
                    <v-btn
                        x-small
                        @click="navigate"
                        @keypress.enter="navigate"
                        role="link">
                      History
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <router-link custom v-slot="{ navigate }" :to="`/quotations/view/${quotation.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row>
          <v-col class="text-right">
            <v-btn @click="runBulkActions()" large>Save</v-btn>
          </v-col>
        </v-row>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
    <v-dialog
        v-model="showMessage"
        max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="orange darken-2"
              text
              @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
        return this.$store.state.userid;
    },
  },
  data() {
    return {
      setChaseAll: false,
      setLoseAll: false,
      chaseCountFilter: 9,
      filteredQuotations: [],
      message: '',
      quotations: [],
      page: 1,
      length: 0,
      showMessage: false,
      totalVisible: 9,
      total: 0,
    };
  },
  watch: {
    page() {
      this.getQuotations();
    },
  },
  methods: {
    chaseAll() {
      this.setChaseAll = !this.setChaseAll;
      if (this.setChaseAll === true) {
        for (let i = 0; this.filteredQuotations.length > i; i += 1) {
          this.filteredQuotations[i].chase = true;
        }
      } else {

        for (let i = 0; this.filteredQuotations.length > i; i += 1) {
          this.filteredQuotations[i].chase = false;
        }
      }
    },
    filter() {
      if (this.chaseCountFilter < 9) {
        this.filteredQuotations = this.quotations.filter((quotation) => quotation.chaseCount === this.chaseCountFilter);
      } else {
        this.filteredQuotations = this.quotations;
      }
    },
    getQuotations() {
      axios.get(`/quotations/getMyOpen/${this.page}/${this.userid}.json?token=${this.token}`)
        .then((response) => {
          this.quotations = response.data.quotations;
          this.filteredQuotations = response.data.quotations;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
          this.hide = false;
        });
    },
    loseAll() {
      this.setLoseAll = !this.setLoseAll;
      if (this.setLoseAll === true) {
        for (let i = 0; this.filteredQuotations.length > i; i += 1) {
          this.filteredQuotations[i].lost = true;
        }
      } else {

        for (let i = 0; this.filteredQuotations.length > i; i += 1) {
          this.filteredQuotations[i].lost = false;
        }
      }
    },
    resetFilter() {
      this.chaseCountFilter = 9;
      this.filter();
    },
    runBulkActions() {
      const postData = {
        quotations: this.filteredQuotations,
        user: this.userid,
      };
      axios.post(`/quotations/bulkUpdate.json?token=${this.token}`, postData)
          .then((response) => {
            this.showMessage = true;
            this.message = response.data.message;
            this.quotations = [];
            this.getQuotations();
          })
          .catch((error) => {
            console.log(error);
            this.hide = false;
          });
    },
  },
  mounted() {
    this.getQuotations();
  },
};
</script>
